<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <grafico-passagens-por-dia-da-semana
          :placa="placa"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <grafico-passagens-por-ponto-de-captura
          :placa="placa"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {

    components: {
      GraficoPassagensPorPontoDeCaptura: () => import('@/components/chart/perfil-veiculo/GraficoPassagensPorPontoDeCaptura'),
      GraficoPassagensPorDiaDaSemana: () => import('@/components/chart/perfil-veiculo/GraficoPassagensPorDiaDaSemana'),
    },

    props: {
      placa: {
        type: String,
        default: '',
      },
    },

    data () {
      return {

      }
    },

    methods: {

    },

  }
</script>
